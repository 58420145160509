import type { TextFieldProps } from '@components/text-field'
import TextField from '@components/text-field'
import { Combobox } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, type ReactNode, useRef, useState } from 'react'
import slugify from '@sindresorhus/slugify'

export interface OptionProps {
  disabled?: boolean
  value: string
  children: ReactNode
}

export const Option = ({ value, children, ...props }: OptionProps) => (
  <Combobox.Option value={value} {...props} as={Fragment}>
    {({ active }) => (
      <li
        key={slugify(value)}
        className={clsx(
          'flex w-full font-semibold p-2 items-center rounded-lg cursor-pointer',
          active && 'bg-gray-50'
        )}>
        {children}
      </li>
    )}
  </Combobox.Option>
)

export interface AutocompleteProps extends Omit<TextFieldProps, 'onChange' | 'onBlur'> {
  children: ReactNode
  disabled?: boolean
  value: any
  query?: string
  getValueLabel?: (value: any) => string
  onChange?: (value: any) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onQuery?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

/**
 * Autocomplete component provides an input field with autocomplete functionality.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} [props.disabled=false] - Whether the input is disabled.
 * @param {string} props.value - The current value of the input.
 * @param {string} [props.query=''] - The initial query string.
 * @param {function} props.onChange - Callback function called when the value changes.
 * @param {function} props.onQuery - Callback function called when the query changes.
 * @param {function} props.getValueLabel - Function to get the label for a given value.
 * @param {React.ReactNode} props.children - The children elements to be rendered as options.
 * @param {Object} props.props - Additional properties to be passed to the input field.
 *
 * @example
 * <Autocomplete
 *   value={selectedValue}
 *   query="initial query"
 *   onChange={(newValue) => console.log('Value changed:', newValue)}
 *   onQuery={(event) => console.log('Query changed:', event.target.value)}
 *   getValueLabel={(value) => value.label}
 * >
 *   <Combobox.Option value="Option 1">Option 1</Combobox.Option>
 *   <Combobox.Option value="Option 2">Option 2</Combobox.Option>
 * </Autocomplete>
 */
const Autocomplete = ({
  disabled = false,
  value,
  query = '',
  onChange,
  onBlur,
  onQuery,
  getValueLabel,
  children,
  ...props
}: AutocompleteProps) => {
  const [ourValue, setOurValue] = useState(value)
  const [ourQuery, setOurQuery] = useState(query)
  const containerRef = useRef<HTMLDivElement>(null)

  // called when typing into text field
  const ourOnQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOurQuery(event.target.value)
    if (typeof onQuery === 'function') onQuery(event)
  }

  const ourOnChange = (selection: any) => {
    let newValue = selection ?? ''
    if (selection != null) {
      setOurQuery(getValueLabel?.(selection) ?? selection)
    }
    setOurValue(newValue)
    onChange?.(newValue)
  }

  const ourOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    const target = event.target

    const isOutside = Array.isArray(containerRef)
      ? containerRef
          .filter((r) => Boolean(r.current))
          .every((r) => r.current && !r.current.contains(target))
      : containerRef.current && !containerRef.current.contains(target)

    if (isOutside && ourQuery !== ourValue) ourOnChange(ourQuery)
    onBlur?.(event)
  }

  return (
    <div className="relative" ref={containerRef}>
      <Combobox disabled={disabled} value={value} nullable onChange={ourOnChange}>
        {/* render as a div to prevent space key from closing options */}
        <Combobox.Button className="w-full" as="div">
          <Combobox.Input
            {...props}
            as={TextField}
            displayValue={() => ourQuery}
            onChange={ourOnQuery}
            onBlur={ourOnBlur}
          />
        </Combobox.Button>
        <Combobox.Options
          className={clsx(
            'absolute z-10 overflow-y-auto shadow w-full p-3 bg-white border rounded-lg mt-1',
            props.isError && 'invisible'
          )}>
          <>
            {/* This is a hack that will allow free style entries on [ENTER] key down
                in text field. */}
            <Combobox.Option className="invisible" value={ourQuery} />
            {children}
          </>
        </Combobox.Options>
      </Combobox>
    </div>
  )
}

Autocomplete.Option = Option

export default Autocomplete
