import React from 'react'
import SvgRenderer from '@cms/renderers/svg-renderer'
import type { ComponentPropsWithoutRef } from 'react'

interface EmptyStateProps extends ComponentPropsWithoutRef<'div'> {}

const EmptyState = ({ children }: EmptyStateProps) => {
  return (
    <div className="pb-20 xl:pb-[108px]">
      <div className="flex flex-col gap-4">{children}</div>
      <SvgRenderer
        ariaHidden
        svgType="IllustrationPlant"
        className="w-[120px] lg:w-[200px] h-auto mt-12"
      />
    </div>
  )
}

export default EmptyState
