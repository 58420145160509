import { NavBar, NavItem, Skeleton } from '@shc/ui'

const WIDTHS = [40, 70, 70, 100]

export default function SearchNavLoading() {
  return (
    <NavBar className="drop-shadow-sm-bottom bg-white">
      {[...Array(4)].map((_, i) => (
        <NavItem key={i} className="pointer-events-none h-[41px]">
          <Skeleton variant="rounded" height={18} width={WIDTHS[i]} />
        </NavItem>
      ))}
    </NavBar>
  )
}
