import { Container, Link, Typography } from '@shc/ui'

const SearchError = () => {
  return (
    <Container>
      <Typography variant="body">
        We&apos;re sorry. An error has occurred on the page you were attempting to view. The
        following information has been reported and we will address this as soon as possible.
      </Typography>
      <br />
      <Typography variant="body">
        If you need assistance, call us at &nbsp;
        <Link href="tel:+18008274277">1-800-82-SHARP</Link>
        &nbsp;(<Link href="tel:+18008274277">1-800-827-4277</Link>), &nbsp;Monday through Friday, 7
        am to 7 pm. If you&apos;re experiencing a medical or psychiatric emergency, call 911
        immediately or go to the nearest emergency room.
      </Typography>
    </Container>
  )
}

export default SearchError
