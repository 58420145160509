'use client'

import { useInstantSearch } from 'react-instantsearch'
import {
  createInsightsMiddleware,
  type InsightsEvent,
  type InsightsProps,
} from 'instantsearch.js/es/middlewares'
import { useLayoutEffect, useState } from 'react'

/**
 * AlgoliaInsights component that integrates Algolia insights middleware with the InstantSearch instance.
 *
 * This component adds a middleware to handle Algolia insights events and send the appropriate payloads
 * to the Algolia insights client. It ensures that duplicate 'viewedObjectIDs' events are not sent.
 *
 * @component
 *
 * @example
 * // Usage in a React component
 * import AlgoliaInsights from './path/to/AlgoliaInsights';
 *
 * function App() {
 *   return (
 *     <div>
 *       <AlgoliaInsights />
 *       <...Other components...>
 *     </div>
 *   );
 * }
 *
 * @returns {null} This component does not render any UI.
 */
export default function AlgoliaInsights() {
  const { addMiddlewares } = useInstantSearch()
  const [middlewareAdded, setMiddlewareAdded] = useState(false)
  let lastEvent = ''

  /**
   * Handles Algolia insights events by sending the appropriate payload to the insights client.
   *
   * @param {InsightsEvent} event - The event object containing the insights method and payload.
   * @param {InsightsProps['insightsClient']} aa - The Algolia insights client function.
   */
  function onEvent(event: InsightsEvent, aa: InsightsProps['insightsClient']) {
    const { insightsMethod, payload } = event
    const payloadString = JSON.stringify(payload)

    // Prevent sending duplicate 'viewedObjectIDs' events
    if (insightsMethod === 'viewedObjectIDs' && lastEvent === payloadString) {
      return
    }

    // Send the event to the Algolia insights client if method and payload are valid
    if (insightsMethod && aa && payload) {
      // @ts-ignore payload type is not correct in Algolia Library
      aa(insightsMethod, payload)
    }

    // Update the last event to the current payload
    lastEvent = payloadString
  }

  useLayoutEffect(() => {
    if (addMiddlewares && !middlewareAdded) {
      const middleware = createInsightsMiddleware({ onEvent })
      addMiddlewares(middleware)
      setMiddlewareAdded(true)
    }
  }, [middlewareAdded, addMiddlewares])

  return null
}
