'use client'

import { createContext, useContext, useMemo, useState, type ReactNode } from 'react'
import type { GeoData } from '@lib/utilities/location-utilities'

type SharedSearchContextType = {
  selectedLocation: GeoData | null
  setSelectedLocation: (location: GeoData | null) => void
}

const SharedSearchContext = createContext<SharedSearchContextType | undefined>(undefined)

export interface SearchContextWrapperProps {
  readonly children: ReactNode
}

export default function SearchContextWrapper({ children }: SearchContextWrapperProps) {
  const [selectedLocation, setSelectedLocation] = useState<GeoData | null>(null)

  // update contextValue value when any state var changes
  const contextValue = useMemo(() => {
    return {
      selectedLocation,
      setSelectedLocation,
    }
  }, [selectedLocation])

  return (
    <SharedSearchContext.Provider value={contextValue}>{children}</SharedSearchContext.Provider>
  )
}

export const useSharedSearchContext = () => {
  const context = useContext(SharedSearchContext)
  // null is valid so we need to be more specific
  if (context === undefined) {
    throw new Error('useSharedSearchContext must be used within a SearchContextWrapper')
  }
  return context
}
