'use client'

import { useEffect, useState, type ComponentPropsWithoutRef } from 'react'
import { type ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import { usePathname } from '@i18n/navigation'
import NextLink from 'next/link'
import clsx from 'clsx'
import { NavBar, NavItem } from '@shc/ui'
import { createFilteredParams } from '@lib/search'
import useAnalytics from '@hooks/use-analytics'
import { useBuildSearchContext } from '@lib/analytics'

export type SearchMenuItem = {
  name: string
  searchIndexRoute: string
}

export type SearchMenu = Record<string, SearchMenuItem>

interface SearchNavProps extends ComponentPropsWithoutRef<'nav'> {
  alignment?: 'center' | 'start'
  searchMenu?: SearchMenu
}

// Constants
export const searchMenuItems: SearchMenu = {
  all: { name: 'All', searchIndexRoute: '' },
  doctors: { name: 'Doctors', searchIndexRoute: 'doctors' },
  locations: { name: 'Locations', searchIndexRoute: 'locations' },
  related_pages: { name: 'Related pages', searchIndexRoute: 'related-pages' },
}

const getSegments = (pathName: string | null) => {
  return pathName?.split('/') ?? []
}

const processQueryParams = (searchParams: ReadonlyURLSearchParams | null) => {
  const rawFilteredParams = createFilteredParams(searchParams)
  return rawFilteredParams.size ? '?' + rawFilteredParams.toString() : ''
}

const SearchNav = ({ searchMenu = searchMenuItems, ...props }: SearchNavProps) => {
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const { track } = useAnalytics()
  const snowplowSearchContext = useBuildSearchContext()

  const [rootSearchPath, setRootSearchPath] = useState(getSegments(pathName)[1])
  const [searchIndexPath, setSearchIndexPath] = useState(getSegments(pathName)[2] ?? '')
  const [queryParams, setQueryParams] = useState(processQueryParams(searchParams))

  useEffect(() => {
    setRootSearchPath(getSegments(pathName)[1])
    setSearchIndexPath(getSegments(pathName)[2] ?? '')
    setQueryParams(processQueryParams(searchParams))
  }, [pathName, searchParams])

  return (
    <NavBar className={clsx(props.className, 'drop-shadow-sm-bottom bg-white')}>
      {Object.entries(searchMenu).map(([_, { searchIndexRoute, name }]) => {
        const isActive = searchIndexRoute === searchIndexPath

        const basePath = `/${rootSearchPath}`
        // Construct the href value based on whether the searchIndexRoute is for 'All'
        const hrefValue =
          searchIndexRoute === ''
            ? `${basePath}${queryParams}` // For 'All', don't add an extra slash
            : `${basePath}/${searchIndexRoute}${queryParams}` // For other routes, include the searchIndexRoute
        return (
          <NavItem
            active={isActive}
            as={NextLink}
            key={searchIndexRoute}
            href={hrefValue}
            onClick={() => {
              track({
                event: {
                  name: 'navigation_click',
                  data: {
                    navigation_tree: `Search > ${name}`,
                    navigation_level: 1,
                    navigation_subject: name,
                    navigation_url: hrefValue,
                  },
                },
                contexts: [
                  { name: 'section', data: { section_name: 'search' } },
                  snowplowSearchContext,
                ],
              })
            }}>
            {name}
          </NavItem>
        )
      })}
    </NavBar>
  )
}

export default SearchNav
